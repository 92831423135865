import { Outlet } from 'react-router-dom';
import { BottomNav } from './BottomNav';
import { AppHeader } from './AppHeader';
import { useParams } from 'react-router-dom';
import { useMainStore } from '../stores/mainStore';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getTokenValues, langNameMap } from '../utils';
import FlagIcon from '@mui/icons-material/Flag';
import { Trans } from './Trans';

export const AppLayout = () => {
  const params = useParams();
  const { language } = getTokenValues();
  const [reportButtonStyle, setReportButtonStyle] = useState({
    bottom: '56px',
    right: '0',
  } as React.CSSProperties);
  if (!params.lang && language) {
    window.location.href = `/${langNameMap[language as keyof typeof langNameMap]}${window.location.pathname}`;
  }
  localStorage.setItem(
    'language',
    params.lang || langNameMap[language as keyof typeof langNameMap]
  );
  const { getTranslations, translations } = useMainStore();
  useEffect(() => {
    getTranslations();
  }, []);
  useEffect(() => {
    setReportButtonStyle(
      window.location.pathname.includes('/chat/')
        ? {
            top: '100px',
            right: '0',
          }
        : {
            bottom: '56px',
            right: '0',
          }
    );
  }, [window.location.pathname]);
  if (Object.keys(translations || {}).length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="my-auto w-full" style={{ height: 'min-content' }}>
      <div className="overflow-hidden flex items-center justify-center from-yellow-50 via-teal-300 to-yellow-50 bg-gradient-to-br">
        <div className="flex flex-col">
          <div className="w-screen md:w-[450px] px-7 py-7 mx-auto bg-white rounded-2xl shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col relative">
                <AppHeader />
                <Outlet />
                <BottomNav />

                <a
                  href="mailto:info@lewdspace.com?
subject=Reporting from LewdSpace&body=
I am writing to bring to your attention inappropriate content that I have encountered on your website.%0A%0A
The specific details of the content in question are as follows:%0A%0A
1. URL of the Content in question:%0A%0A%0A
2. Description of the Content:%0A%0A%0A
3. Relevant law or regulation:%0A%0A%0A
Sincerely,%0A
[FULL NAME]%0A
[CONTACT INFORMATION/EMAIL]"
                  className="absolute right-0 bg-white shadow-lg rounded-full p-2 z-50 flex items-center"
                  aria-label="Report an issue"
                  title="Report an issue"
                  style={reportButtonStyle}
                >
                  <FlagIcon style={{ color: '#FA5C5C' }} fontSize="small" />
                  <span className="ml-1 text-xs text-gray-700 hidden md:inline">
                    <Trans>Report</Trans>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
